import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

interface IUserData {
    id: number,
    username: string,
    email: string
}

const UserProfile: React.FC = () => {
    let { usernameParam } = useParams();

    const [userData, setUserData] = useState<IUserData>();

    useEffect(() => {
        async function getUser() {
            const res = await fetch(`http://64.23.195.55:8080/api/user/${usernameParam}`);
            const data = await res.json();
    
            if (data.username) setUserData(data);

            return data;
        }

        getUser();
    }, []);

    return (
        <>
            {userData?.username ? <div>This is { userData.username }'s profile page. They have the id of { userData.id }. Their email is { userData.email }</div> : <div>Profile not found</div>}
        </>
    );
};

export default UserProfile;
