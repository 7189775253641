import React from "react";
import "../css/Footer.scss";

const Footer: React.FC = () => {
    return (
        <footer>
            Motivul &copy; 2022
        </footer>
    );
}

export default Footer;
