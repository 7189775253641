import './css/App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import UserProfile from './pages/Profile';
import NotFound404 from './pages/NotFound404';
import Sidebar from './component/Sidebar';
import { useEffect, useState } from 'react';
import Landing from './pages/Landing';
import Navbar from './component/Navbar';
import RegisterTemp from './pages/RegisterTemp';

function App() {
    const [passwordProtect, setPasswordProtect] = useState({
        password: ""
    });

    const handlePasswordProtectChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPasswordProtect({
            ...passwordProtect,
            [e.target.name]: e.target.value
        })
    };

    const handlePasswordProtectClick = (): void => {
        if (passwordProtect.password == "drpepper") localStorage.setItem("passwordProtect", "true");
        window.location.reload();
    }

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        async function isUserLoggedIn() {
            try {
                const res = await fetch('http://64.23.195.55:8080/api/loggedIn', {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    },
                });
                
                const data = await res.json();

                sessionStorage.setItem("loggedIn", "true");
                sessionStorage.setItem("username", data.userData.user.username);

                setLoggedIn(true);
                setLoading(false);
            } catch (error) {
                // Not logged in. Remove token in case it's expired, clear session storage, and stop loading
                localStorage.removeItem("token");
                sessionStorage.clear();
                setLoggedIn(false);
                setLoading(false);
            }
        }

        if (loading) isUserLoggedIn();
    }, []);

    return (
        <>
        { (localStorage.getItem("passwordProtect")) ?
            <Router>
                { loading ? "Loading" :
                    <div id="site-container">
                        <div id="main-area" className={loggedIn ? "sidebar" : "navbar"}>
                            { loggedIn ? <Sidebar /> : <Navbar /> }
                            <div id="content-area">
                                <main>
                                    <Routes>
                                        { loggedIn ? <Route path="/" element={<Home />} /> : <Route path="/" element={<Landing />} /> }
                                        <Route path="/registertemp" element={<RegisterTemp />} />
                                        <Route path="/about" element={<About />} />
                                        <Route path="/profile/:usernameParam" element={<UserProfile />} />
                                        <Route path="*" element={<NotFound404 />} />
                                    </Routes>
                                </main>
                            </div>
                        </div>
                    </div>
                }
            </Router>
            :
            <div>
                This page is password protected!<br/>
                <input type="text" placeholder="Password" value={passwordProtect.password} onChange={handlePasswordProtectChange} name="password" /><br/>
                <input type="submit" value="Login" onClick={handlePasswordProtectClick} />
            </div>
        }
        </>
    );
}

export default App;
