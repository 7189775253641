import React from "react";
import { Link } from "react-router-dom";
import "../css/Sidebar.scss";
import Footer from "./Footer";

const Sidebar: React.FC = () => {
    return (
        <div id="sidebar">
            <div>
                <header>
                    <div className="app-name">Motivul</div>
                </header>

                <nav>
                    <div className="link"><Link to="/">Home</Link></div>
                    <div className="link"><Link to="/about">About</Link></div>
                    <div className="link"><Link to="/profile/Boodog">Profile</Link></div>
                </nav>
            </div>
            <Footer />
        </div>
    );
};

export default Sidebar;
