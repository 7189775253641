import React from "react";
import { Link } from "react-router-dom";

const About: React.FC = () => {
    return (
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lacinia tortor ultrices sem bibendum iaculis. In vel elit facilisis, rutrum lectus ut, placerat purus. Pellentesque luctus sed neque et dapibus. Phasellus vel mauris venenatis, facilisis mi mattis, bibendum justo. Etiam pharetra risus eget enim tincidunt gravida. Integer sed sollicitudin dolor. Morbi lacinia est eget sem accumsan, non volutpat lectus mattis. Sed commodo, tellus non sodales porttitor, metus lorem finibus mauris, vitae dapibus ipsum lacus ut elit. Nullam elit massa, vehicula nec dictum id, hendrerit id sem. Donec consectetur tempor efficitur. Vestibulum dapibus interdum ante eu placerat. Proin ut erat suscipit sem facilisis facilisis sed et velit. Pellentesque a turpis erat. Praesent porttitor augue ac augue aliquet, eget luctus sapien cursus. Nam cursus orci nec purus fringilla, quis ultrices sapien tempor.

        <br/><br/><Link to="/">Click here to go home!</Link></div>
    );
};

export default About;
