import React, { useState } from "react";

const Home: React.FC = () => {
    const logout = async() => {
        localStorage.removeItem("token");
        window.location.reload();
    }

    return (
        <div>
            {sessionStorage.getItem("loggedIn") ?
                <>
                    <div>You are logged in! Welcome, {sessionStorage.getItem("username")}</div><br/>
                    <div onClick={logout} className="fauxLink">Log out</div>
                </>
                :
                <></>
            }
        </div>
    );
};

export default Home;
