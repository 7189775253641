import React from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.scss";

const Navbar: React.FC = () => {
    return (
        <div id="navbar">
            <div>test</div>
            <div>test2</div>
        </div>    
    );
};

export default Navbar;
